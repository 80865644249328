import { frequentlyUsedTimezones, parseCoordinates, timezoneOptions } from '@/utils/tz';
import {
  Button,
  Select,
  Modal,
  Empty,
  message,
  Row,
  Col,
  DatePicker,
  Input,
  Typography,
  Tabs,
} from 'antd';
import { CalendarOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { useState, useEffect } from 'react';
import tz_lookup from 'tz-lookup';
import FeatureFlag, { getCurrentEnvironment } from '../FeatureFlag';

const TimezoneConverter = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [displayAllTimes, setDisplayAllTImes] = useState(false);
  const [selectedTime, setSelectedTime] = useState();
  const [selectedTimezone, setSelectedTimezone] = useState();
  const [convertedTimes, setConvertedTimes] = useState([]);
  const [selectedTargetTimezone, setSelectedTargetTimezone] = useState([]);
  const [typedCoordinates, setTypedCoordinates] = useState();
  const [coordinates, setCoordinates] = useState();

  useEffect(() => {
    if (!selectedTime || !selectedTimezone || !selectedTargetTimezone?.length) {
      setConvertedTimes([]);
      return;
    }
    const inputTime = dayjs.tz(selectedTime.format('YYYY-MM-DD HH:mm'), selectedTimezone);
    const outputTimes = selectedTargetTimezone.map((zone) => {
      const timeInZone = inputTime.tz(zone).format('YYYY-MM-DD HH:mm');
      const label = timezoneOptions.find((ele) => ele.value === zone).label;
      return { label, timeInZone };
    });
    setConvertedTimes(outputTimes);
  }, [selectedTime, selectedTimezone, selectedTargetTimezone]);

  useEffect(() => {
    if (typedCoordinates) {
      const result = parseCoordinates(typedCoordinates);
      if (result) {
        setCoordinates(result);
      } else {
        setCoordinates();
      }
    } else {
      setCoordinates();
    }
  }, [typedCoordinates]);

  return (
    <>
      <Button icon={<CalendarOutlined />} onClick={() => setIsModalOpen(true)}>
        时区助手
      </Button>
      <Modal
        title="时区助手"
        open={isModalOpen}
        onCancel={() => {
          setIsModalOpen(false);
          setConvertedTimes([]);
          setSelectedTargetTimezone([]);
          setSelectedTime();
          setSelectedTimezone();
          setTypedCoordinates();
          setCoordinates();
        }}
        footer={null}
        destroyOnClose={true}
        width={640}
      >
        <Tabs
          items={[
            {
              key: '时区转换',
              label: '时区转换',
              children: (
                <>
                  <Row style={{ paddingTop: 10 }}>
                    <Col md={12} xs={24}>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                      >
                        <div>
                          <Select
                            onChange={setSelectedTimezone}
                            showSearch
                            name="timezone"
                            placeholder="时间所在时区"
                            options={displayAllTimes ? timezoneOptions : frequentlyUsedTimezones}
                            style={{ width: '90%' }}
                          />
                          <span>的</span>
                        </div>
                        {displayAllTimes ? (
                          <a onClick={() => setDisplayAllTImes(false)}>仅显示常用时区</a>
                        ) : (
                          <a
                            onClick={() => {
                              setDisplayAllTImes(true);
                              message.success('已可选择所有时区');
                            }}
                          >
                            找不到时区选项？点击加载所有时区
                          </a>
                        )}
                      </div>
                    </Col>
                    <Col md={12} xs={24}>
                      <div>
                        <DatePicker
                          onChange={setSelectedTime}
                          colProps={{ md: 12, xs: 24 }}
                          name="time"
                          placeholder="要转换的时间"
                          showTime={{ format: 'HH:mm' }}
                          format="YYYY-MM-DD HH:mm"
                        />
                        <span>等同于</span>
                      </div>
                    </Col>
                  </Row>
                  <Row
                    style={{
                      marginTop: 10,
                    }}
                  >
                    <Col span={24}>
                      <span>目标时区: </span>
                      <Select
                        style={{ width: '80%' }}
                        showSearch
                        name="timezone"
                        options={displayAllTimes ? timezoneOptions : frequentlyUsedTimezones}
                        mode="multiple"
                        onChange={setSelectedTargetTimezone}
                      />
                    </Col>
                  </Row>
                  <div>转换结果:</div>
                  {convertedTimes?.length ? (
                    <div style={{ paddingTop: 18 }}>
                      <table>
                        <tbody>
                          {convertedTimes.map(({ label, timeInZone }, index) => (
                            <tr key={index}>
                              <td style={{ fontSize: 18, fontWeight: 'bold', paddingRight: 50 }}>
                                {label}:
                              </td>
                              <td style={{ fontSize: 18 }}>{timeInZone}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    <Empty description={<Typography.Text type="secondary">暂无</Typography.Text>} />
                  )}
                </>
              ),
            },
            {
              key: '城市时区查询',
              label: '城市时区查询',
              disabled: getCurrentEnvironment() === 'production',
              children: (
                <FeatureFlag off="production">
                  用Google搜索：学生所在城市 经纬度，复制第一个结果到下方输入框里
                  <Typography.Paragraph type="secondary">
                    <blockquote>
                      请使用以下格式之一
                      <ul
                        style={{
                          listStyleType: 'square',
                        }}
                      >
                        <li>
                          十进制度数, 例如: 40.7128,-120.5795。纬度范围为[-90, 90], 经度范围[-180,
                          180]
                        </li>
                        <li>
                          度数加方向, 例如: 40.7128° N,120.5795° W。格式为 dd.dddd° N/S, ddd.dddd°
                          W/E
                        </li>
                      </ul>
                      请确保您的输入在正确的范围内，并使用正确的格式
                    </blockquote>
                  </Typography.Paragraph>
                  <Input
                    addonBefore="经纬度"
                    onChange={(e) => {
                      setTypedCoordinates(e.target.value);
                    }}
                  />
                  {coordinates ? (
                    <Typography.Paragraph>
                      该坐标最可能在的时区为:
                      <span> </span>
                      <Typography.Text strong style={{ fontSize: '1.1rem' }}>
                        {tz_lookup(coordinates[0], coordinates[1])}
                      </Typography.Text>
                    </Typography.Paragraph>
                  ) : null}
                </FeatureFlag>
              ),
            },
          ]}
        />
      </Modal>
    </>
  );
};

export default TimezoneConverter;

import { cn, utcOffset, utcOffsetText } from '@h6you/timezone-names-cn';

export const timezoneOptions = Object.entries(cn)
  .filter((elem) => {
    const [tz, _] = elem;
    return tz?.includes('/');
  })
  .map((elem) => {
    let [tz, zhName] = elem;
    let offset = utcOffsetText(tz) || '';
    offset = offset.replace('GMT', 'UTC');
    if (tz === 'Asia/Shanghai') {
      zhName = '亚洲/北京';
    }
    return {
      label: `${zhName} ${tz}(${offset})`,
      value: tz,
    };
  });

const frequentlyUsed = [
  'Asia/Shanghai',
  'America/Toronto',
  'America/Vancouver',
  'America/Edmonton',
  'America/Winnipeg',
  'America/New_York',
  'America/Chicago',
  'America/Los_Angeles',
  'Canada/Newfoundland',
  'America/Halifax',
  'Europe/London',
  'Australia/Sydney',
  'Australia/Perth',
  'Australia/Adelaide',
  'Pacific/Auckland',
];

export const frequentlyUsedTimezones = frequentlyUsed.map((tz) => {
  let offset = utcOffsetText(tz) || '';
  offset = offset.replace('GMT', 'UTC');
  let zhName = cn[tz];
  if (tz === 'Asia/Shanghai') {
    zhName = '亚洲/北京';
  }
  if (tz === 'America/Vancouver') {
    zhName = '美洲/温哥华/圣地亚哥';
  }
  if (tz === 'America/Los_Angeles') {
    zhName = '美洲/西雅图/洛杉矶';
  }
  return {
    label: `${zhName}(${offset})`,
    value: tz,
  };
});

export const DSTtimeZones = {
  Canada: 'America/Toronto', // 加拿大多伦多时区
  USA: 'America/New_York', // 美国东部时区
  UK: 'Europe/London', // 英国伦敦时区
  Australia: 'Australia/Sydney', // 澳大利亚悉尼时区
  'New Zealand': 'Pacific/Auckland', // 新西兰奥克兰时区
};

export const DSTcountryName = {
  Canada: '加拿大',
  USA: '美国',
  UK: '英国',
  Australia: '澳大利亚',
  'New Zealand': '新西兰',
};

/** @param {"latitude" | "longitude"} type
 * @param {string | undefined | null} coord
 * @return {[number, number] | null} result
 */
export function parseCoordinates(coord) {
  if (coord === undefined || coord === null || coord === '') {
    return false;
  }

  const [lat, lon] = coord
    .toString()
    .trim()
    .replaceAll('°', '')
    .replaceAll('，', ',')
    .replaceAll(' ', '')
    .split(',');
  if (!lat || !lon) {
    return false;
  }

  let latValue = parseFloat(lat);
  if (isNaN(latValue) || latValue < -90 || latValue > 90) {
    return false;
  }
  let lonValue = parseFloat(lon);
  if (isNaN(lonValue) || lonValue < -180 || lonValue > 180) {
    return false;
  }

  // Regular expression for matching coordinates
  const latRegex = /^(-?(?:90(?:\.0+)?|(?:[0-8]?\d(?:\.\d+)?)))°?\s*[NS]?$/i;
  const lonRegex = /^(-?(?:180(?:\.0+)?|(?:(?:1[0-7]\d)|(?:[0-9]?\d))(?:\.\d+)?))°?\s*[EW]?$/i;

  if (!latRegex.test(lat) || !lonRegex.test(lon)) {
    return false;
  }
  if (lat.includes('S') && latValue > 0) {
    latValue = -latValue;
  }
  if (lon.includes('W') && lonValue > 0) {
    lonValue = -lonValue;
  }

  return [latValue, lonValue];
}

import { Input, InputNumber } from 'antd';

const getInitValue = (type, value) => {
  if (type === 'minutes') {
    if (value?.minutes) {
      return Number(value.minutes);
    }
    if (typeof value === 'number') {
      const h = Math.floor(value / 60);
      const m = value - h * 60;
      return m;
    }
    return 0;
  }
  if (type === 'hours') {
    if (value?.hours) {
      return Number(value.hours);
    }
    if (typeof value === 'number') {
      const h = Math.floor(value / 60);
      return h;
    }
    return 0;
  }
  return 0;
};

const RawDurationView = ({ value, onChange, disabled = false, step = 1, ...rest }) => {
  const onHourChange = (hours) => {
    const minutes = getInitValue('minutes', value);
    const duration = (hours || 0) * 60 + (minutes || 0);
    onChange(duration);
  };

  const onMinutesChange = (minutes) => {
    const hours = getInitValue('hours', value);
    const duration = (hours || 0) * 60 + (minutes || 0);
    onChange(duration);
  };

  return (
    <Input.Group>
      <InputNumber
        disabled={disabled}
        min={0}
        value={getInitValue('hours', value)}
        onChange={onHourChange}
      />
      <span style={{ marginLeft: 5, marginRight: 15 }}>小时</span>
      <InputNumber
        disabled={disabled}
        min={0}
        step={step}
        value={getInitValue('minutes', value)}
        onChange={onMinutesChange}
      />
      <span style={{ marginLeft: 5 }}>分钟</span>
    </Input.Group>
  );
};

export default RawDurationView;

/** @typedef {('production'|'staging'|'develop')} env

/**
 * @returns {env} environment
 */
export const getCurrentEnvironment = () => {
  if (window.location.hostname === 'guoji.savvyuni.com.cn') {
    return 'production';
  }
  if (window.location.hostname === 'intl-erp.savvyuni.ca') {
    return 'staging';
  }
  return 'develop';
};

/**
 * @param {env?} on
 * @param {env?} off
 * @param {ReactNode} children
 * @returns {ReactNode | null} content
 */
const FeatureFlag = ({ on, off, children }) => {
  const currentEnv = getCurrentEnvironment();
  if (on && on === currentEnv) {
    return children;
  }
  if (off && off !== currentEnv) {
    return children;
  }
  return null;
};

export default FeatureFlag;
